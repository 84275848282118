$timeline-dot-color: var(--secondary-color); //#6CE5E8;
$timeline-line-color: rgba(208, 204, 204, 0.74);

.timeline-title {
    font-size: 1em;
    font-weight: bold;
}

.timeline-item:last-child:before{
    box-shadow: unset;
    width: 16px;
    height: 16px;
    border: 1px solid #babcbd;
    left: -24px;
}

.timeline-items {
  padding: 0 0 0 1.25em;
  list-style: none;

  &.milestones:after {
  }

  .timeline-item {
    border: 1px solid #d7d7de;
    border-radius: 20px;

    &:before {
      content: "";
      width: 6px;
      height: 6px;
      z-index: 3;
      background-color: #fff;
      border-radius: 100%;
      position: absolute;
      top: 47%;
      left: -20px;
      transform: translateY(-50%);
      box-shadow: 0 0 0 4px $timeline-dot-color;
    }

    &:after{
      position: absolute;
      left: -23px;
      top: 55%;
      content: '';
      border-left: 2px dotted $timeline-line-color;
      margin-left: 5px;
      height: 100%;
    }

    &:last-child:after{
      display:none;
    }

    date {
      font-weight: 500;
    }

    svg {
      width: 21px !important;
    }
  }

}