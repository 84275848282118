.contact-page {
  text-align: center;

  form {
    text-align: left;
    margin: 1rem auto;
    width: 80%;
  }

  @include media-breakpoint-down(sm) {
    form {
      width: 100%;
    }
  }
}