.breadcrumb_custom {
    background-color: #D7D7DE;

    & .breadcrumb li {
        font-size: $font_size_18;
        font-weight: 500;

    }

    .breadcrumb{
      flex-wrap: nowrap;
      white-space: nowrap;
      overflow: auto;

      @include media-breakpoint-up(sm) {

      }

      @include media-breakpoint-up(md) {
      }
      @include media-breakpoint-up(lg) {
      }
      @include media-breakpoint-up(xl) {

      }

      li {
        display: inline-block;
        overflow: hidden;
        text-overflow: ellipsis;
        min-width: auto;
      }

      li:first-child{
        overflow: visible;
        width: auto;
      }
      li:not(:first-child){
      }

      li:last-child{
      }

      .breadcrumb-item.active{
        color: $text-black;
      }
    }

    a {
      color: $brand-color;
    }
}