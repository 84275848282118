.activity-icons {
  &.home {
    right: 40px;
  }

  & a {
    border: 2px solid $white;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    border-radius: 50%;
    color: $white !important;
    font-size: 12px;
    text-decoration: none !important;

    @include focus-hover;

    @include media-breakpoint-up(md) {
      width: 40px;
      height: 40px;
      i, span {
        font-size: 18px;
      }
    }

    &.custom {
      img {
        width: 20px;
        height: 16px;
      }
    }
  }

  & .text {
    font-weight: 600;
    color: #a9afb6;
  }

}


.activity-icons {
  & a {
    border-color: $text-color;
    color: $text-color !important;
  }

  & a:not(:last-child) {
    margin-right: 0.5rem;
  }
}

a[role="button"] {
  &:hover,
  &:focus {
    color: $secondary-color;
    text-decoration: underline !important;

  }
}


