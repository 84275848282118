// -----------------------------------------------------------------------------
// When having several themes, this file contains everything related to the
// default one.
// -----------------------------------------------------------------------------

//html {
//  font-size: 16px;
//}


//h1, .h1{
//  font-size: 32px;
//}
//
//h2, .h2{
//  font-size: $font_size_24;
//}
//
//h3, .h3{
//  font-size: $font_size_20;
//}
//
//h4, .h4{
//  font-size: $font_size_18;
//}
//h5, .h5{
//  font-size: $font_size_16;
//}
//
//h6, .h6{
//  font-size: $font-size-12;
//}

@media (min-width: 300px) {
  .footer-desc {
    font-size: 12px;
  }
}

@media (min-width: 992px) {
  .border-line-2 {
    border-right: 1px solid #a9afb6;
    //padding-right: 40px;
  }

  .side_bar_gap {
    //padding-left: 40px;
  }

  .footer-desc {
    font-size: $font-size-14
  }
}


@media (min-width: 1500px) {
  .ms-xxxl-auto {
    margin-left: auto !important;
  }
  .col-custom-40 {
    width: 40%;
    flex: none;
  }
  .col-custom-60 {
    width: 60%;
    flex: none;
  }
  .col-xxxl-1 {
    width: 16%;
    flex: none;
  }
  .px-xxxl-5 {
    padding-left: 40px;
    padding-right: 40px;
  }
}

@media (min-width: 1600px) {
  .banner {
    .home-banner-title {
    }
  }
}

@media (min-width: 1920px) {
  .footer-desc {
    font-size: 1rem;
  }
}