.white-card, .color-card {
  $border-radius: $default-border-radius;
  $icon-blurb-size: 48px;

  box-shadow: 0 100px 80px rgba(28, 32, 37, 0.05), 0 12.5216px 10.0172px rgba(28, 32, 37, 0.025);

  border-radius: $border-radius;

  // TODO: Move to _widgets.scss file
  .widget-header {
    border-top-right-radius: $border-radius;
    border-top-left-radius: $border-radius;
    padding: 0 1rem;
    h2,
    h3{
      padding: 0.75rem 0;
    }
  }

  .widget-content {
    border-bottom-right-radius: $border-radius;
    border-bottom-left-radius: $border-radius;
    height: 100%;
    padding: 0.75rem 1rem;

    p:last-child {
      margin-bottom: 0 !important;
    }

    h3 {
      margin-bottom: 0;
    }
  }

  .blurb-icon {
    width: $icon-blurb-size; // Requested changes from Geordie; changed by James from 60px to 45px
    height: $icon-blurb-size;
  }

  .sponsor-item {
    &:last-child {
      .sponsor-email {
        margin-bottom: 0;
      }
    }

    p {
      margin-bottom: 0;
    }
  }

}

.idea-card{
  border-radius: $default-border-radius;
}

.feature-card{
  border-top-right-radius: 0;
  border-top-left-radius: 0;
}

.color-card {
  background-color: $color-card-bg;
  color: $color-card-color;

  .nav-tabs .nav-link.active {
    color: $color-card-color !important;

    &::after {
      color: $color-card-color !important;
    }
  }
}

.widget-sbg-spacing {
  .widget-header {
    padding: 1rem !important;
  }

  .widget-content {
    padding: 0rem 1.0rem 1.0rem !important;
    @include media-breakpoint-up(md) {
      padding: 0rem 1.5rem 1.5rem !important;
    }
  }
}


.white-bg, .white-card:not([class*="widget-bg-image"]) {
  background: $text-white;
}

.widget-bg-image.text-white * {
  color: $text-white;
}