// -----------------------------------------------------------------------------
// This file contains styles that are specific to the home page.
// -----------------------------------------------------------------------------
.border-line {
    width: 1px;
    height: 97%;
    margin: 0 auto;
    background: #a9afb6;
}

.custom-marker li {
    position: relative;
    &:before {
        content: "";
        position: absolute;
        width: 10px;
        height: 10px;
        background: $brand-color;
        border-radius: 50%;
        left: -20px;
        top: 50%;
        transform: translateY(-50%);
    }
}

.btn-check:focus+.btn,
.btn:focus {
    box-shadow: none;
}

.custom-list.custom-marker {
    padding-left: 15px;
}

.custom-list.custom-marker li:before {
    background: #000;
    left: -15px;
    width: 6px;
    height: 6px;
}

.footer_logo_img {
    height: 40px;
}

.about-us-content p{
  margin-bottom:0;
}
.about-us-content p:not(:first-child){
  margin-top: 1rem;
}

.project-row {
  //column-gap: 1.5rem;
}

.project-row>*{
  padding-left: calc($project-card-spacing * 0.5);
  padding-right: calc($project-card-spacing * 0.5);
}