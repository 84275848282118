// -----------------------------------------------------------------------------
// This file contains all @font-face declarations, if any.
// -----------------------------------------------------------------------------


// font sizes
.fs-20 {
  font-size: $font_size_20 !important;
}

.fs-18 {
  font-size: $font_size_18 !important;
}

.fs-16 {
  font-size: $font_size_16 !important;
}

.fs-14 {
  font-size: $font_size_14 !important;
}

.fs-12 {
  font-size: 12px !important;
}

.fs-36 {
  font-size: $font_size_36  !important;
}

.fs-30 {
  font-size: $font_size_30 !important;
}

.fs-60 {
  font-size: $font_size_60 !important;
}

.fs-24 {
  font-size: $font-size-24 !important;
}

.fs-5{
  font-size: 1rem !important;
}

.fs-28{
  font-size: 28px !important;
}

// font-weight

.fw-800 {
  font-weight: 800;
}

.fw-600 {
  font-weight: 600;
}

.fw-500 {
  font-weight: 500;
}

.fw-400 {
  font-weight: 400;
}



