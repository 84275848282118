.project .nav-tabs .nav-link {
    color: #5E656E;
    font-size: $font_size_18;
    font-weight: 700;
    border: 0px !important;
}

.project .nav-tabs .nav-link.active {
    background: unset;
    color: $brand-color;
    position: relative;

    &:focus {
        isolation: unset;
    }

    &::after {
        background-color: $brand-color;
        bottom: 0;
        content: "";
        height: 6px;
        position: absolute;
        left: 0;
        width: 100%;
        z-index: 1;
    }
}

.nav-tabs {
    position: relative;
    &::after {
        content: "";
        height: 6px;
        width: 100%;
        position: absolute;
        bottom: -6px;
        left: 0;
        border-radius: 100px;
        background-color: #f9f9f9;
        z-index: -1;
    }
}