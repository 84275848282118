// -----------------------------------------------------------------------------
// This file contains CSS helper classes.
// -----------------------------------------------------------------------------

/**
 * Clear inner floats
 */

.clearfix::after {
    clear: both;
    content: "";
    display: table;
}

/**
 * Boostrap 4 to 5 floats
 */
.float-left {
  @extend .float-start;
}

.float-right{
  @extend .float-end;
}

/**
 * Main content containers
 * 1. Make the container full-width with a maximum width
 * 2. Center it in the viewport
 * 3. Leave some space on the edges, especially valuable on small screens
 */


/**
 * Hide text while making it readable for screen readers
 * 1. Needed in WebKit-based browsers because of an implementation bug;
 *    See: https://code.google.com/p/chromium/issues/detail?id=457146
 */

.hide-text {
    overflow: hidden;
    padding: 0;
    /* 1 */
    text-indent: 101%;
    white-space: nowrap;
}


/**
 * Hide element while making it readable for screen readers
 * Shamelessly borrowed from HTML5Boilerplate:
 * https://github.com/h5bp/html5-boilerplate/blob/master/src/css/main.css#L119-L133
 */

.visually-hidden {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}

.hidden {
  visibility: hidden;
  display: none;
}

.default-cursor {
  cursor: default;
}

.hide,
.popover-arrow {
    display: none;
}

.show {
  display: block !important;
}

.ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  &-md {
    @include media-breakpoint-up(md) {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
}

.border-focus-0{
  &:focus{
    border: 0 !important;
    box-shadow: none !important;
  }
}


.readmore {
  overflow: hidden !important;
  max-height: 150px;

  transition: height;
  transition-duration: 500ms;

  // for expend/collapse text
  +a {
    display: block;
    margin: 0.25rem 0 0.75rem;
    text-decoration: none !important;

    &:focus,
    &:hover {
      text-decoration: underline  !important;
    }
  }


  +a,
  +a.readmore-expand {
    color: $white;
  }

  .readmore-collapse {
    &:focus,
    &:hover {
      color: $white;
    }
  }
}
