
.nested-list-container {
  ol {
    counter-reset: item;

    li {
      display: block;
      padding: 1rem 0;

      p {
        font-size: 1rem !important;
      }

      &:before {
        content: counters(item, ".") " ";
        counter-increment: item;
        font-weight : 600;
      }
    }
    @include media-breakpoint-down(md){
      padding-left: 1.3rem;
    }
  }
}