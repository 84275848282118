.register_image {
    width: 84.27px;
    height: 40px;
}
.view-registration #header-content {
    display:none;
}
.authentication.register .banner {
    height: 100%;
}
.authentication.register .container-fluid {
    padding: 0px;
}
.authentication.register .row {
    margin: 0px;
}
.authentication.register {
    min-height: calc(100vh - 60px);
    position:relative;
}
.authentication.register .banner {
    height: calc(100vh - 60px);
    position:relative;
}
.authentication.register .register-form-area {
    height: calc(100vh - 60px);
    position:relative;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 0px 20px;
}

.view-registration .custom-footer, .to-top {
    display: none;
}

.register-text-area {
    margin: 0 auto;
    max-width: 400px;
    width: 100%;
    height: calc(100vh - 60px);
    text-align: center;
    padding-top:50px;
    padding-bottom: 70px;
    display: flex;
    align-items: center;
}
.login-register-title {
    font-size: 30px;
}
.register-form-area .form-box {
    margin: 0 auto;
    max-width: 400px;
    width: 100%;
    padding:30px 0px;
}
fieldset.btn-group.radio {
    flex-wrap: wrap;
}
fieldset.btn-group.radio [type=radio] {
    margin-right: 5px;
    margin-bottom: 5px;
}
fieldset.btn-group.radio label {
    width: calc(100% - 20px);
    margin-bottom: 5px;
}
.input-group-text {
    position: relative;
    width: 38px;
    display: block;
    line-height: 28px;
}
input[type=checkbox] {
    width: 16px;
    height: 16px;
    padding: 0;
    border: 1px solid rgb(0 0 0 / 25%);
    margin: 2px 10px 0 5px;
}
.input-group input#jform_com_fields_locationaddress {
    border:0px;
    background: #fff;
    cursor: not-allowed;
}
.form-control:disabled {
    background: #fff;
    cursor: not-allowed;
}
.input-group .btn.btn-link {
    position: relative;
    z-index: 2;
    border: 0;
    border-left: 1px solid #ced4da;
    /* background: #06a4d8; */
    color: #1c2025;
    text-decoration: none;
    height: 38px;
    padding-left: 15px;
    padding-right: 20px;
}

.mfp-content .white-popup-block > label {
    display: block;
    margin-bottom: 5px;
}
.btn-yellow {
    background: #ff9900;
    color:#fff;
    border-color: #ff9900;
}
.view-registration .overview-intro p {
    font-size: 1.3rem;
}
.view-registration .banner .h4 {
    width: 100%;
}
.line-height-34, .view-registration .overview-intro p {
    line-height: 34px;
}
@media (min-width: 1200px) {
.view-registration .overview-intro p {
    font-size: 1.3rem!important;
}
}
@media (max-width: 767px) {
    .location-input-group button.custom-btn {
        border-radius: 10px !important;
    }
    .location-input-group>.form-control {
        width: 100%;
        border-radius: 10px;
        border-top-right-radius: 10px!important;
        border-bottom-right-radius: 10px !important;
        margin-bottom: 15px;
    }
    .input-group.location-input-group button.custom-btn:nth-child(3) {
        margin-left: 0px !important;
        margin-right: 10px;
    }
}

.view-registration {
  input + label, label.checkbox {
    color: #70757d;
    font-weight: normal;
    margin: 0.3rem;
  }
}
