// -----------------------------------------------------------------------------
// This file contains all styles related to the button component.
// -----------------------------------------------------------------------------
%share_custom_btn{
  background: $brand-color;
  border-radius: $btn-border-radius;
  border-color: $brand-color;
  color: $button-color;
  font-weight: 700;
  padding: 10px 30px;
  transition: all 0.3s ease;
  white-space: normal;
  word-break: break-word;
  @extend .shadow;
}

.custom-no-hover-btn{
  @extend %share_custom_btn;
  &:active,
  &:target,
  &:focus, // Fixes WCAG issue in ideas page
  &:hover {
    background: $brand-color;
    border-color: $brand-color;
    color: $button-color;
  }
}

.btn a,
.custom-btn {
    @extend %share_custom_btn;
    &:active,
    &:target,
    &:focus, // Fixes WCAG issue in ideas page
    &:hover {
        background: $secondary-color !important;
        color: $button-color !important;
        border-color: $secondary-color;
        text-decoration: none;
    }
}

.btn-blue {
    background-color: $brand-color;
    border-radius: 10px;
    padding: 10px 5px;
    color: $button-color;
    //text-transform: uppercase;
    font-weight: 700;
    &:hover {
        background: $secondary-color;
        color: $button-color;
        text-decoration: none;
    }
}

.btn_lg {
    padding: 8px 60px;
    font-size: $font-size-30;
}

.btn-report-comment{
  white-space: nowrap;
}

.text-brand{
  color: $brand-color;

  &:focus,
  &:hover{
    color: $secondary-color;
  }
}

.btn-link{
  border: none;
  background: transparent;
}