body, html {
  height: 100%;
}

.pv-content-widget {
  overflow-wrap: break-word;
}

@media (max-width: 1600px) {

  .custom-btn {
    padding: 8px 20px;
  }
  .border-line-2 {
    border-right: 1px solid #a9afb6;
    //padding-right: 30px !important;
  }
  .side_bar_gap {
    //padding-left: 30px !important;
  }

  .breadcrumb_custom {
    .breadcrumb {
      li {
      }
    }
  }
  .input-theme {
    height: 44px;
  }
  .tags {
    li {
      a {
        font-size: .675rem;
      }
    }
  }

  .btn_lg {
    padding: 8px 70px;
    font-size: $font_size_18;
  }

}

@media (max-width: 1440px) {
  .custom-popover,
  .custom-comment-popver {
    bottom: 0;
    right: 0;
  }
  .banner {
  }
  .btn_lg {
    padding: 8px 56px;
  }
  .btn_action {
    font-size: 1.25rem;
    padding: 10px 32px;
  }
}

@include media-breakpoint-down(xxl) {
  .btn_action {
    font-size: 1.15rem;
  }
  .social-share-icons.inner_pages.home.stories-detail {
    top: 50%;
    transform: translateY(-50%);
  }

  .social-share-icons.inner_pages.home {
    top: 31px;
    right: 22px;
  }

  .activity-icons {
    a:not(:last-child) {
      margin: 0 8px 0px 0;
    }

    a:last-child {
      margin: 0;
    }

    a img {
      width: 15px;
      height: 15px;
    }

    a {
      width: 30px;
      height: 30px;
    }
  }

  .all-ideas-banner .social-share-icons.home {
    right: 22px; // for home page
  }
}

@include media-breakpoint-down(xl) {
  .time-remaining-box {
    li {
      padding: 0 10px;
    }
  }

  .social-share-icons.inner_pages.home {
    top: 60px;
    right: 18px;
  }
}

@include media-breakpoint-down(lg) {

  .mx-15 {
    margin-left: -5px;
    margin-right: -8px;
  }

  .navbar-toggler {
    margin-left: 10px;
  }
  #search_trigger_mobile {
    padding: 0px;
    height: auto;
    width: auto;
    display: flex;
  }
  .navbar-toggler-icon {
  }

  .social-share-icons.inner_pages.home.idea-detail {
    transform: translateY(70%);
  }
  .project-img {

    .h-250 {
      height: auto;
    }
  }
  .pb-100 {
    padding-bottom: 100px !important;
  }
  .social-share-icons.inner_pages.home {
    width: 100%;
    justify-content: flex-start;
    top: 86%;
    right: 0;
    padding-left: 50px;
  }
  .search_box {
    width: 100%;
    top: 58px;
    right: 0;
  }
  .search_box .form-control {
    height: 56px;
    border-radius: 0;
  }
  .search_box .btn-submit {
    border-radius: 0;
    height: 56px;
    padding: 0 14px;
  }
  .social-share-icons.home {
    a {
      margin: 0 0.5rem;
    }

    a.first {
      margin: 0 0.5rem 0 0;
    }

    a.last {
      margin: 0 0 0 0.5rem;
    }

    bottom: 10px;
    right: 36%;
  }

  .all-banners,
  .all-ideas-banner {
    .social-share-icons {
      a {
        margin: 0 0.5rem;
      }

      a.first {
        margin: 0 0.5rem 0 0;
      }

      a.last {
        margin: 0 0 0 0.5rem;
      }

      &.home {
        top: 90%;
        right: 0;
        left: 0;
        padding-left: 50px;
      }
    }

    .view-consultation .social-share-icons.home {
      top: 90%;
      right: 0;
      left: 0;
      padding-left: 50px;
    }

    .custom-marker {
      li:before {
        top: 13px;
      }
    }
  }
  .project {
    .nav-tabs {
      .nav-link {
        span {
          display: none;
        }
      }
    }
  }

  .slide_sm {
    width: 100%;
    overflow-x: scroll;
  }

  .social-share-icons.footer-social {
    position: static;
    justify-content: center;
    margin-bottom: 1rem;
  }

  .custom-header {
    a.nav-link,
    .language_sm,
    .menu-social {
      border: 1px solid #D7D7DE;
      padding: 1rem;
      background-color: #fff;
    }
  }
  .custom-header {
    .nav_bar {
      position: absolute;
      width: 100%;
      top: 99%;
      z-index: 999;
      left: 0px;
    }

    .navbar {
      position: fixed;
      width: 100%;
      top: 0;
      background-color: #efefef;
      z-index: 999;
    }
  }
  .breadcrumb_custom.py-4 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
    margin-top: 60px;
  }
  /*.rounded-20,  // applying equal bordering for all viewports
  .white-card {
      border-radius: 10px !important;
  }*/
  .activity-icons {
    a.unfollow-popover {
      display: none;
    }
  }
  .form-container.login-form-box .login-footer {
    /*position: fixed;*/
    position: absolute;
    bottom: 10px;
  }
  .form-container .login-footer {
    position: unset;
  }
  .bg-mobile-login {
    //background-image: url('../images/auth-bg.jpg');
    height: 100%;
    max-height: -webkit-fill-available;
    min-height: -webkit-fill-available;
    background-size: cover;
    background-attachment: fixed;
    padding-top: 0px !important;
    @include bg-overlay-mobile();
    background-repeat: no-repeat;
    background-position: center;
  }
  .authentication {
    height: 100%;
    height: 100vh;
    max-height: -webkit-fill-available;
    min-height: -webkit-fill-available;
    /*padding-top: 10px;*/
  }
  .authentication .register-form-area, .authentication.register .register-form-area {
    height: auto;
    padding: 0;
  }
  .password-info {
    font-weight: normal;
    color: #d5d5d5;
  }

  .bg-mobile-login {
    h2,
    label,
    span,
    input:focus,
    .form-control {
      color: #fff;
      font-weight: normal;
    }

    input, select, .input-group input#jform_com_fields_locationaddress, .form-control:disabled {
      /*  background: linear-gradient(270deg, #1C2025 69.57%, #1C2025 69.58%, rgba(28, 32, 37, 0) 100%); */
    }


    input::placeholder {
      color: #d5d5d5;
      font-weight: normal;
    }

    input:focus {
      background-color: unset;
    }

    input[type=checkbox] {
      background: #fff;
      margin-right: 5px;
    }

    /*.input-group .btn.btn-link {
        border-left: 0;
        background: #fff;
    }*/
    .input-group .btn.btn-link {
      border-color: #444;
      background: transparent;
      color: #fff;
      padding: 5px 10px;
    }

    .form-select {
      color: #FFF;
    }

    select.form-select option {
      background: #0d6efd;
    }

    select#jform_com_fields_age_bracket:invalid {
      color: #d5d5d5;
    }

    .view-registration input + label, .view-registration label.checkbox {
      color: #ffffff;
      font-size: 14px;
    }

    label {
      font-weight: normal !important;
      font-size: 14px;
    }

    .form-control {
      background-color: transparent;
      font-size: 14px;
    }

    textarea.form-control {
      background: linear-gradient(270deg, #15171a 60%, #15171a 60%, rgb(21 23 26 / 45%) 100%);
      border: 0;
      height: 100px;
      resize: none;
    }
  }

  .authentication .input-group {
    border: 0;
    background: linear-gradient(270deg, #15171a 60%, #15171a 60%, rgb(21 23 26 / 45%) 100%)
  }
  .input-group input#jform_com_fields_locationaddress {
    border: 0;
    color: #fff;
    background: transparent;
    cursor: not-allowed;
  }
  .form-select {
    background-color: transparent;
    //color:#fff; // This breaks the idea form category select field
    font-size: 14px;
    background-image: url('../images/select-arrow.png');
    background-size: 12px 12px;
  }

  .authentication form .h2 + fieldset > p {
    color: #fff;
    font-size: 14px;
  }
  .view-registration input + label, .view-registration label.checkbox {
    color: #fff;
    font-size: 14px;
  }
  input[type=checkbox], input[type=radio] {
    width: 14px;
    height: 14px;
  }
  .authentication .register-form-area {
    padding: 0px 0px;
  }
  .authentication form .h2, .authentication form h2 {
    font-size: 20px;
  }
  .mobile-produced-by-txt {
    font-size: 12px;
    /*margin-bottom: 10px;*/
  }
  .authentication {
    .input-group {
      /*  border: 0; */
    }
  }
  .profile.banner {
    position: relative;
  }
  .bottom-sm-txt {
    bottom: 24% !important;
  }
  .banner-desc {
    width: 100%;
    padding: 0;
  }
  .txt-left-border:after {
    display: none;
  }
  .social-share-icons.home {
    width: 100%;
    justify-content: center;
    //top: 86%;
    right: 0;
  }
  .custom_accordion {
    .accordion-item {
      .accordion-button:not(.collapsed) {
        font-size: 1rem;
      }

      .accordion-button {
        font-size: 1rem;
      }

      .accordion-button:not(.collapsed),
      .accordion-button {
        line-height: 34px;
      }
    }
  }

  .border-line-2 {
    border-right: 0;
    //padding-right: calc(var(--bs-gutter-x) * .5) !important;
  }

  .side_bar_gap {
    //padding-left: calc(var(--bs-gutter-x) * .5) !important;
  }
  .social-share-icons.inner_pages.home.stories-detail {
    justify-content: center;
    padding-left: 0;
  }
  .social-share-icons.inner_pages.home.stories-detail {
    justify-content: center;
    padding-left: 0;
    transform: translateY(80%);
  }
  .all-ideas-banner.social-share-icons {
    top: 90%;
  }

  .authentication a {
    color: #d5d5d5 !important;
  }
  .authentication .register-form-area, .authentication.register .register-form-area {
    padding: 0;
    /*min-height: calc(100vh - 48px);*/
    height: 100vh;
    max-height: -webkit-fill-available;
    min-height: -webkit-fill-available;
  }
  .form-container {
    /*min-height: calc(100vh - 48px);*/
    height: 100vh;
    max-height: -webkit-fill-available;
    min-height: -webkit-fill-available;
    padding-top: 60px;
    padding-bottom: 50px;
  }
  .register-form-area .form-box {
    padding: 30px 0 15px;
  }
}

@include media-breakpoint-down(md) {
  .profile-main .white-card {
    box-shadow: none;
    background-color: transparent !important;
  }
  .custom-footer {
    background-color: #fff;
  }
  .social-share-icons.inner_pages.home {
    padding-left: 20px;
  }
  .banner_title_1 {
    font-size: $font-size-30 !important;
    line-height: 36px !important;
  }
  .banner {
    height: auto;
    //background-position: 74% 100%;
    //padding: 70px 10px 100px;
    padding: 40px 10px 80px;
  }
  .breadcrumb_custom {
    .breadcrumb {
      li {
        font-size: $font-size-14;
      }
    }
  }
  .time-remaining-box {
    p {
      margin-bottom: 0;
    }
  }
  .tags_all_ideas {


    .stat-icon {
      img, .width-30, .width-35 {
        width: 25px !important;
      }
    }

    .first {
      width: 25px;
      height: 25px;
      line-height: 25px;
    }

    .first.fs-4,
    .last.fs-18 {
      font-size: $font-size-14 !important;
    }

    .last {
      height: 25px;
      line-height: 21px;
    }
  }
  .tags_all_ideas > .fs-4 {
    font-size: 1rem !important;
  }

  .activity-icons {
    margin-left: auto;
  }

  .get-involved-cta {
    height: 250px !important;
  }
  .write_your_comment_box {
    width: 320px;
    left: 30px;
  }
  .faq_title {
    font-size: $font-size-30 !important;
  }
  .custom_accordion.faq_main {
    .accordion-button:not(.collapsed),
    .accordion-button {
      font-size: 1.5rem;
      line-height: 22px;
      padding-right: 0;
    }
  }
  .accordion-item:first-of-type {
    .accordion-button {
      border-radius: 20px;
    }
  }
  .accordion-item:last-of-type {
    .accordion-button.collapsed {
      border-radius: 20px;
    }
  }
  .w-sm-100 {
    width: 100% !important;
  }
  .custom-popover,
  .custom-comment-popver {
    border-radius: 10px;
    bottom: 10%;
    right: 0;
  }
  .rating-wrapper label {
    padding: 0 2rem 0 0;
    font-size: 1.5rem;
  }
  .rating-wrapper:after {
    top: 6px;
  }
  .bottom-sm-txt {
    bottom: 18% !important;
  }
  .fs-sm-20 {
    font-size: $font_size_20;
  }
  /*.stories.banner {
      height: 520px;
  }*/
  .custom-comment-popver.story-detail {
    bottom: 4%;
    right: 0;
  }
  .banner:not(.detail-content) {

  }
  .social-share-icons.home {
    bottom: 10px;
    right: 0;
    width: 100%;
    padding-left: 14px;
  }
  .social-share-icons.stories-detail-banner {
    justify-content: center;
    padding-left: 0;
  }
  .lamb-svg {
    width: 60px;
  }
  .white-card.cc {
    background-color: $bg-color;
    box-shadow: none;
  }
  .white-card.bg-sm-transparent {
    box-shadow: none;
    background-color: #e5e5e5
  }
  .bg_grey.bg-sm-white {
    background-color: #fff
  }
  .banner {
    h4 {
      width: 100%;
    }
  }
  .btn_lg {
    padding: 6px 16px;
    font-size: 1.25rem;
  }
  .h-350 {
    height: 250px;
  }


  .banner.stories-detail-banner {
    height: 480px;
  }


  .activity-icons {
    a {
      width: 36px;
      height: 36px;
    }
  }
  .social-share-icons {
    a {
      width: 36px;
      height: 36px;
    }
  }
  .profile-detail-box {
    .profile-img {
      .uplaod-file-box {
        bottom: 0;
        right: 25%;
      }
    }
  }
  .faq_main {
    .accordion-item {
      .accordion-button {
        font-size: 16px;
        line-height: 20px;
        padding-top: 10px;
        padding-bottom: 10px;
        border-radius: 10px;
      }
    }

    .accordion-item:last-of-type,
    .accordion-item:first-of-type {
      .accordion-button {
        border-radius: 10px;
      }
    }
  }

  .video-story-wrapper {
    iframe {
      width: 100%;
    }
  }
  .input-theme {
    padding: .375rem .375rem;
  }

}

@include media-breakpoint-down(sm) {
  .social-share-icons.home {
    padding-left: 20px;
    padding-bottom: 15px;
  }

  .bg-white.px-md-3.px-2 {
    padding-left: 4px !important;
    padding-right: 4px !important;
  }
  .tags_all_ideas {


    .last {
      height: 26px;
      line-height: 25px;
    }

    .first {
      width: 20px;
      height: 27px;
      line-height: 25px;
    }
  }
  .login_check .form-group input {
    padding: 0;
    height: initial;
    width: initial;
    margin-bottom: 0;
    display: none;
    cursor: pointer;
  }
  .login_check .form-group label {
    cursor: pointer;
    position: relative;
    font-weight: 400;
    font-size: $font-size-14;
    padding-left: 30px;
  }
  .login_check .form-group label:before {
    content: '';
    -webkit-appearance: none;
    background-color: transparent;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
    padding: 9px;
    display: inline-block;
    position: absolute;
    vertical-align: middle;
    cursor: pointer;
    margin-right: 5px;
    border: 1px solid #BABCBD;
    border-radius: 5px;
    top: 2px;
    left: 0;
  }
  .login_check .form-group input:checked + label:after {
    content: "";
    width: 6px;
    height: 11px;
    border: 1px solid #fff;
    border-width: 0 1px 1px 0;
    display: block;
    position: absolute;
    top: 6px;
    left: 7px;
    transform: rotate(45deg);
  }
  .container {
    max-width: $min-width-custom;
  }
  .display-5 {
    font-size: 1.8rem;
  }
  .resource-card {
    background-color: transparent;
    box-shadow: none;
  }

  .project .nav-tabs li {
    height: 100%;
  }
}

@media (max-width: 400px) {
  .tags_all_ideas > div:not(:last-child) {
    margin-right: 6px;
  }
  .project {
    .nav-tabs {
      .nav-link {
        font-size: 0.875rem;
        padding: 0.35rem 0.75rem;
      }
    }
  }
  .write_your_comment_box {
    width: 300px;
    left: 0;
  }
  .fs-xsm-18 {
    font-size: 18px;
    margin-top: .5rem;
  }
  .custom-popover,
  .custom-comment-popver {
    bottom: 24%;
  }
  .banner {
    padding: 40px 4px 80px;
  }
  .tags_all_ideas > .fs-4.fs-xsm-14 {
    font-size: 14px !important;
  }
}

@media (max-width: 320px) {

  .slide_sm.profile {
    .activity-icons {
      margin-left: auto !important;
      margin-top: 0 !important;
    }
  }

  .banner {
    padding: 40px 4px 80px;
  }
  .write_your_comment_box {
    width: 280px;
    left: 0;
  }
  .btn_lg {
    padding: 8px 40px;
    font-size: 1.25rem;
  }

  .bg_grey {
    .custom-btn.px-5 {
      padding: 8px 24px !important;
    }
  }
}


@media only screen and (max-width: 500px) {

  .video-detail-banner {
    padding: 0 !important;
    //background-image: none !important;
    //height: auto !important;
    box-shadow: none;
    //backdrop-filter: blur(50px);
    .video-story-wrapper {
      overflow: hidden;
      position: relative;

      iframe {
        min-width: 350px;
        min-height: 207px;
        max-height: 450px;
      }
    }
  }

  .project {
    .nav-tabs {
      .nav-link {
        font-size: .875rem;
        padding: 0.35rem 0.75rem;
      }
    }
  }

}

@media only screen and (min-width: 600px) and (max-width: 990px) {
  .search_box .form-control {
    //width: 85%;
  }
  .com_search .white-card .search input[type=text] {
    width: 73%;
  }
}

@media only screen and (min-width: 992px) {
  .video-story-wrapper {
    height: 65vh;
    width: 100vw;
    overflow: hidden;
    position: relative;

    iframe {
      position: absolute;
      top: 8%;
      left: 0%;
      z-index: 999;
      height: 55vh;
      width: 100%;
    }
  }
  .get-involved-cta {
    .custom-btn {
      position: absolute;
    }
  }
}

@media only screen and (min-width: 300px) and (max-width: 400px) {
  .search_box .form-control {
    width: 69%;
  }
  .com_search .white-card .search input[type=text] {
    width: 54%;
  }
}

.sr-only {
  color: #636363;
}

@include media-breakpoint-up(lg) {
  .stories-browser {
    min-height: 290px;
  }

  .article-bottom-widgets,
  .article-top-widgets{
    padding-left: calc(var(--bs-gutter-x)* .5);
    padding-right: calc(var(--bs-gutter-x) * .5);
  }
}

@include media-breakpoint-down(sm) {
  .wordbreak-sm-hypen,
  h1, h2, h3, h4, h5, h6, h7 {
    word-break: break-word;
    hyphens: auto;
  }
}

// Remove break ideation social share icons
//@include media-breakpoint-up(lg) {
//  .social-share-icons {
//    height : calc(240px + 2rem);
//  }
//}
