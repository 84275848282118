.select_faq {
  width: 150px;
  height: 40px;
  border: 1px solid #d7d7de;
  border-radius: 10px;
  background-color: rgba(255, 255, 255, 1);
}
.info-tooltip {
  background: $text-white;
  box-shadow: 0px 1px 24px 5px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  & .popover-arrow {
    display: none;
  }
  & .custom-marker li {
    position: relative;
    &::before {
      background-color: #5ede2f;
      top: 10%;
      transform: unset;
    }
  }
}

.faq_main {
  .askus {
    //background-color: $text-white; applying color-card theme
    border-radius: 20px;
    border-color: rgba(215, 215, 222, 1);
    border-width: 0 0 1px 0;
    box-shadow: none;
    //color: $brand-color;

    &-item-icons {
      a:focus{
        outline-offset: 4px;
      }

      a:not(:last-child){
        margin-right: 0.5rem;
      }

      & a {
        align-items: center;
        color: var(--text-color);
        display: flex;
        font-size: 12px;
        height: 26px;
        justify-content: center;

        padding: 0;
        text-decoration: none !important;
        width: 26px;



        @include media-breakpoint-up(md) {
          height: 26px;
          width: 26px;

          i, span {
            font-size: 18px;
          }
        }
      }
    }
  }

    header {
      align-items: center;
      box-shadow: none;
      //color: $brand-color;
      display: flex;
      font-size: 22px;
      font-weight: 700;
      text-align: left;

      padding: 1rem 1.25rem;
      position: relative;
      width: 100%;
      overflow-anchor: none;
      transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out, border-radius .15s ease;

  }
}
