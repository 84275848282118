#top-intro{
  p {
    @extend .fs-18;
    @extend  .fw-400;
    @extend .mb-2;
  }
}

.search{
  .phrases-box{
    .controls label{
      @extend .px-3;
      input{
        margin-right: .5rem;
      }
    }
  }
}