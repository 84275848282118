
.gallery-card {
  background: $text-white;
  box-shadow: 0px 100px 80px rgba(28, 32, 37, 0.05),
    0px 12.5216px 10.0172px rgba(28, 32, 37, 0.025);
  border-radius: 20px;

  & .gallery-img { // media thumbnail
    border-radius: 20px;
    overflow: hidden;
    //max-height: 257px;
    img {
      object-fit: cover;
      //height: 235px;

    }

    &:before {
      content: "";
      position: absolute;
      background: linear-gradient(
        180deg,
        rgba(28, 32, 37, 0) 53.12%,
        #1c2025 100%
      );
      width: 100%;
      right: 0;
      height: 100%;
      top: 0;
    }
  }

  & .gallery-detail {
    right: 0;
    bottom: 0;
    border-radius: 20px;
  }
}

.widget-media-thumbnail{
  object-fit: cover;
  height: 113px;
  width: 113px;
}