.custom_accordion {
    &.accordion-item:last-of-type {
        border-bottom: 0;
    }

    & .accordion-header-wrapper,
    & .accordion-item {
        border-color: rgba(215, 215, 222, 1);
        border-width: 0 0 1px 0;
        background-color: $color-card-bg;
        color: $color-card-color;
        & .accordion-button {
            color: $color-card-color;
            background-color: $color-card-bg;
            font-size: $font_size_18;
            font-weight: 700;
            line-height: 1.5;
        }
        & .accordion-button:not(.collapsed) {
            background-color: $color-card-bg;
            color: $color-card-color;
            box-shadow: none;
            font-size: $font_size_18;
            padding-bottom: 0;
        }
        & .accordion-button:focus {
            border-color: transparent;
            box-shadow: none;
        }
    }
    & .first-item:not(.collapsed)::after{
       background-image: none;
    }
}

.feature-askus {
  &.custom_accordion .accordion-item:first-child{
    & .accordion-button {
      color: $color-card-color;
      font-size: $font_size_24;
      padding-bottom: 1rem;
    }
  }

  .accordion-button:after {
    position: absolute;
    right: 0;
    padding-left: 5px;
  }
  .accordion-item:first-child {
    .accordion-header .accordion-button > svg{
      height: 48px;
      min-width: 48px;
      width: 48px;
    }
  }
}

.faq_main {
    & .accordion-item:last-of-type {
        border-bottom: 0;
        border-radius: 20px;
        & .accordion-button {
            border-radius: 20px;
        }
    }
    & .accordion-item:first-of-type {
        border-radius: 20px;
        & .accordion-button {
            border-radius: 20px;
        }
    }
    & .accordion-item {
        border-radius: 20px;
        border-color: rgba(215, 215, 222, 1);
        border-width: 0 0 1px 0;
        & .accordion-button {
            border-radius: 20px;
            color: #1c2025;
            font-size: 22px;
            font-weight: 700;
        }
        & .accordion-button:not(.collapsed) {
            background-color: $color-card-bg;
            color: $color-card-color;
            box-shadow: none;
        }
        & .accordion-button:focus {
            border-color: transparent;
            box-shadow: none;
        }
    }
}
