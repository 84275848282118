// -----------------------------------------------------------------------------
// This file contains all styles related to the footer of the site/application.
// -----------------------------------------------------------------------------

.custom-footer {
  border-top: 1px solid #babcbd;
  & a {
    color: $text-color;
  }
  & .custom-border {
    width: 2px;
    height: 40px;
    background: #ccc;
  }
}

