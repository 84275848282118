.search_box {
  height: 44px;

  z-index: 2;
  min-width: 200px;
  &.search_box_mobile{
    margin: 1rem auto 1rem;
    width: 80%;
  }
  .input-group {
    flex-wrap: nowrap;
  }
  .form-control {
    border: 1px solid #d7d7de;
    border-radius: 10px;
    height: 44px;
  }

  .btn-submit {
    background: #1c2025;
    border-radius: 0px 10px 10px 0px;
    color: #fff;
    border: 0;
    height: 44px;
    //right: 0;
    //top: 0;
    padding: 0 30px;
    //margin-right: 1rem;
  }

  .js-finder-search-query,
  .search-query{
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
  }

  .awesomplete {
  }
}

//Search page

.com-finder,
.com_search{
  a.result__title-link{
    color: $brand-color;
    display : inline;

    font-size: 1.2rem;
    font-weight: 700;
    justify-content: start;
    text-decoration: none;

    span.result__title-text {
      display: inline;
    }
  }


  .color-card{
    .search{
      @media screen and (max-width: 600px){
        .ordering-box{
          padding-left: 1rem;
          padding-right: 1rem;
          margin-top: 1.2rem;
        }
        .browse_label{
          padding: 0 .8rem;
        }
      }
      input[type="text"]{
        width: 85%;
        &::placeholder {
          font-size: 14px;
        }
        @media screen and (max-width: 600px) {
          width: auto;
        }
      }
      .browse_label{
        border: 0;
        font-weight: 500 !important;
      }
      label{
        font-weight: 400;
        input[type="checkbox"]{
          margin-right: 3px;
          vertical-align: sub;
        }
        input[type="radio"]{
          vertical-align: middle;
        }
      }
      select{
        font-size: .775rem;
        padding: 0.3rem 0.5rem;
        border-radius: 5px;
        border-color: #ccc;
      }
    }
  }
}