.profile.banner {
    height: 40vh;
}

.profile-detail-box {
    background: $color-card-bg;
    color : $color-card-color;
    box-shadow: 0px 100px 80px rgba(28, 32, 37, 0.05), 0px 12.5216px 10.0172px rgba(28, 32, 37, 0.025);
    border-radius: 20px;
    margin-top: -150px;

    & .profile-img {
        & img {
            background-color: white;
            border-radius: 50%;
            filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25)) drop-shadow(0px 12.52px 10.02px rgba(0, 0, 0, 0.025));
            height: 250px;
            object-fit: cover;
            width: 250px;
        }
        & .upload-btn {
            color: $text-white;
            width: 35px;
            height: 35px;
            text-align: center;
            background: #000;
            border-radius: 100%;
            line-height: 35px;
            bottom: 0;
            right: 40px;
            cursor: pointer;
        }
        & .uplaod-file-box {
            bottom: 0;
            right: 30%;
        }
    }
    & .other-detail li {
        border-bottom: 1px solid #babcbd;
    }
    & .avatars {
        padding-left: 25px;
        & img {
            border-radius: 50%;
            margin-left: -20px;
            filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25)) drop-shadow(0px 12.52px 10.02px rgba(0, 0, 0, 0.025));
        }
        & span:last-child {
            color: #babcbd;
            font-weight: 700;
            gap: 10px;
            font-size: 18px;
        }
    }
}

.profile-main {
    .nav-tabs li {
      z-index: 2; // correcting table IPhone issue
    }

    & .nav-tabs:after {
        z-index: 1;
        background: #d7d7de;
    }

    & .nav-tabs .nav-link.active:after {
        z-index: 2;
    }
    & .white-card {
        box-shadow: none;
        background: #efefef;
    }
}

#updateProfile{
  label {
    font-size: initial;
  }

  .form-check-inline {
    label {
      padding-right: 2.5rem;
    }
  }

  input[type='checkbox']{
  }

  .checkboxes {
    padding-left: 1.5rem;
  }
}