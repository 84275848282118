.view-login #header-content, .view-reset #header-content, .view-login .custom-footer, .view-reset .custom-footer {
    display: none;
}
.authentication {
    .social-share-icons {
        right: 0;
        bottom: 0;
        left: 0;
        margin: 0 auto;
        width: 100%;
    }
    & .container-fluid {
        padding: 0px;
    }
    & .container-fluid > .row {
        margin: 0px;
    }
    & .banner {
        height: calc(100vh - 60px);
        position:relative;
    }
    & .register-form-area {
        height: calc(100vh - 60px);
        position:relative;
        overflow-y: auto;
        overflow-x: hidden;
        padding: 0px 20px;
    }
    & form h2 {
        border-bottom: 1px solid #babcbd;
        font-size: 1.5rem;
    }
    & a {
        color: $brand-color;
    }
    & .input-group {
        border: 1px solid #babcbd;
        border-radius: 10px;
        height: 40px;
    }
    & .input-group-text {
        position: relative;
        &::after {
            content: "";
            background-color: #babcbd;
            width: 1px;
            height: 25px;
            right: 0;
            position: absolute;
            z-index: 1;
        }
    }
    & i {
        color: #d7d7de;
    }
}
.authentication a.link-action {
    font-size: 14px;
}
.authentication {
    min-height: calc(100vh - 60px);
    position:relative;
}
.login-register-title {
    font-size: $font-size-48;
}

.password-info {
    font-size: 12px;
    line-height: 16px;
    display: block;
    margin-top: 8px;
    color: #6c757d;
}
.form-control {
    font-size: 16px;
}
input[type=checkbox] {
    background: #fff;
    -webkit-appearance: auto;
    -moz-appearance: auto;
    appearance: auto;
}
.form-container {
    padding-bottom: 80px;
}
.form-container.login-form-box {
    height: calc(100vh - 60px);
    display: flex;
    align-items: center;
}
.login-footer {
    position: absolute;
    width: 100%;
    bottom: 0;
}
.required-filed {
    position: relative;
}
.required-filed:before {
    content: '*';
    position: absolute;
    color: #EB0000;
    right: 5px;
    z-index: 1;
    top: 50%;
    transform: translateY(-50%);
    height: 8px;
    line-height: 1;
    display: block;
}
.form-group > label {
    font-weight: 500!important;
    margin-bottom: 0.3rem !important;
}

.form-group label span.star {
    color: #EB0000;
}
.show-profile label {
    font-weight: 400!important;
    margin-bottom: 0!important;
}

.view-login, .view-reset{
  .custom-footer{
    margin-top: 0 !important;
    @include media-breakpoint-down(lg) {
      display: none;
    }
  }
}

.view-login {
  .overview-intro {
    div {
      text-align: center;
    }
    p {
      @extend .line-height-34;
      @extend .fs-4;
    }
  }
  .banner .h4 {
    width: 100%;
  }
}
.view-login .overview-intro p {
    font-size: 1.3rem;
}

@include media-breakpoint-down(lg) {
  .view-login, .view-reset, .view-registration{
    #header-content{
      display: none;
    }
  }
}
