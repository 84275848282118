// Generic star ratings
.ui-star-ratings {
  display: flex;
  flex-direction: row;
  padding: 16px;
  position: relative;

  .star {
    flex: none;
    order: 1;
    align-self: flex-start;
    margin: 0 4px;
    transition: all .2s ease-in-out;

    > a {
      text-decoration: none;

      &:focus {
        outline-offset: 3px;
      }
    }

    .pv-icon-rate-outline{
      @extend .far;
    }

    > a > span.pv-icon:not(on):before {
      content: "\f005";
      color: $black;
    }

    &:hover,
    &.hover {
      .pv-icon-rate-outline{
        @extend .fa-solid;
      }

      > a > span.pv-icon:before {
        content: "\f005";
        color: $brand-color;
      }
    }

    &.on {
      .pv-icon-rate-outline {
        @extend .fas;
      }

      > a > span.pv-icon:before {
        content: "\f005";
        color: $black;
      }
    }
  }
}
.idea-stars {
  svg {
    display: block;
    margin: 0 0.15rem 0 0;
    &:last-child{
      margin-right: 0;
    };
  }
}
