.txt-left-border:after {
  content: "";
  width: 2px;
  height: 130px;
  background-color: $brand-color;
  position: absolute;
  left: 0;
  top: 0;
}

.banner-desc {
  padding: 10px 0 0 30px;
  width: 70%;
}

.list_dot {
  width: 5px;
  height: 5px;
  background-color: #1c2025;
  border-radius: 50%;
  display: block;
}

.input-theme {
  height: 56px;
  border-color: #d7d7de;
  border-radius: 10px;
}

.form-control:focus {
  box-shadow: none;
}

.browse_label {
  background-color: #1c2025;
  border-radius: 0 10px 10px 0;
  padding: 0 30px;
}

.border-2-white {
  border: 2px solid $text-white;
}

.img_rounded {
  width: 48px;
  height: 48px;
  border: 1px solid #d7d7de;
  border-radius: 50%;
}

.gallery-icon {
  right: 8px;
  top: 11px;
  z-index: 2;
}

.triangle-topright {
  width: 0;
  height: 0;
  z-index: 1;
  opacity: 0.8;
  border-top: 64px solid $text-color;
  border-left: 70px solid #0000;
}

.drag-area {
  background: #d9d9d9;
  border: 1px solid #d7d7de;
  border-radius: 10px;

  button {
    background: transparent;
    border: none;
    outline: none;
  }

  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 5px;
  }
}

.drag-area.active {
  border: 2px solid $text-white;
}

.select2-container {
  display: block !important;

  &--default {

  }

  .select2-search--inline {
    .select2-search__field {
      height: 24px;
      padding: 0 !important;
      margin-top: 0;
      margin-left: 7px;
    }
  }

}

.select2-container.select2-container--default.select2-container--open {
  z-index: 1100;
}

.select2-container--default .select2-selection--multiple {
  border: 1px solid #d7d7de !important;
  border-radius: 10px;
}


.select2-container .select2-selection--multiple {
  padding: 10px 10px;
}

.hidden_input {
  opacity: 0;
  visibility: hidden;
  overflow: hidden;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice {
  background: #d7d7de;
  border: 1.075px solid #d7d7de;
  border-radius: 5px;
  margin-top: 0;
  padding: 4px 4px 4px 18px !important;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice__remove {
  color: #1c2025;
  border: 0;
  font-size: 1.3em;
  top: 0;
  left: 0px;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice__display {
  color: rgba(28, 32, 37, 1);
  font-size: 14px;
}


.mce-container, .mce-container *, .mce-widget, .mce-widget *, .mce-reset {
  border-radius: 10px;
  font-size: 16px !important;
}

#time-remaining .title {
  font-size: 1rem;
  text-transform: uppercase;
  margin-bottom: 1rem;
}

.time-remaining-box {
  h2 {
    margin-bottom: 0;
  }

  ul {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }

  li {
    padding: 0 10px;
  }

  li:first-child {
    padding-left: 0;
  }

  li:last-child {
    padding-right: 0;
  }

  .txt {
    padding: 0 2rem;
  }
}

.h-350 {
  height: 350px;
}

.theme-box {
  .box_desc {
    font-size: $font-size-12;
  }
}

.bg_grey {
  .fs-2 {
    font-size: $font-size-30 !important;
  }
}

.border-yellow {
  border-bottom: 1px solid $brand-color;

  &:hover {
    color: $brand-color;
    text-decoration: none;
  }
}

.short-para-2-line {
  display: -webkit-box;
  line-clamp: 2;
  min-height: 48px;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.short-para-3-line {
  display: -webkit-box;
  line-clamp: 3;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.short-para-1 {
  display: -webkit-box;
  line-clamp: 1;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.bg_grey {
  h4 {
    font-size: $font_size_20;
  }
}

.view-consultation{
  .project-page-content-wrapper{
    background: $white;
    .product-desc{
      padding: 1.5rem;
    }
  }
}


.product-desc,
.site-articles,
.about {
  h2 {
    font-size: 24px;
    font-weight: 800;
  }

  img {
    @extend #{'.img-fluid'}
  }

  // responsive video embed
  @include media-breakpoint-down(lg) {
    div.iframe-container {
      overflow: hidden;
      position: relative;
      width: 100%;

      &::after {
        padding-top: 56.25%;
        display: block;
        content: '';
      }

      iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }
  }

}

.feature-askus {
  .bg-light-overlay.text-white {
    color: rgba(var(--bs-white-rgb),var(--bs-text-opacity))!important; // override color on background image

    .accordion-button {
      color: rgba(var(--bs-white-rgb),var(--bs-text-opacity))!important; // override color on background image
    }
  }

  &.custom_accordion .accordion-item:first-child {
    & .accordion-button {
      color: #1c2025;
      font-size: $font_size_24;
      padding-bottom: 1rem;
    }
  }

  .accordion {
    .accordion-header-wrapper:first-child {
      border-top-left-radius: $default-border-radius;
      border-top-right-radius: $default-border-radius;
    }
    .accordion-item:last-of-type {
      border-radius: 0;
    }
  }


  .bg-light-overlay {
    .accordion-button:not(.collapsed),
    .accordion-button {
      background-color: transparent;
      color: #fff;
    }

    .accordion-header-wrapper:first-child {
      background-color: transparent;
      @extend .rounded-20;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;

      .accordion-button {
        background-color: transparent;
        color: $white;
        padding-top: 0;
      }

    }
  }

  &.bg-light-overlay {
    .accordion-item:first-child {
      border: none;
    }
  }
}