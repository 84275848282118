// the position of system message has changed. please follow the latest position

.system-message::after,
.system-message::before {
  content: "" !important;
  display: none;
}

#system-message-container {
  .alert::after,
  .alert::before {
    content: "" !important;
    display: none;
  }

  .alert-dismissible {
    padding-left: 0;
  }

  .alert-error {
    color: #842029;
    background-color: #f8d7da;
    border-color: #f5c2c7;
  }

  .alert p:before {
    border-radius: 5px;
    content: "";
    display: inline-block;
    font-family: "Font Awesome 6 Free";
    font-weight: 900;
    padding: 0 1rem;
  }
}


#system-message-container .alert.alert-danger p:before, #system-message-container .alert.alert-error p:before {
  content: "\f071";
}

#system-message-container .alert.alert-info p:before {
  content: "\f05a";
}

#system-message-container .alert.alert-success p:before {
  content: "\f058";

}

#system-message-container .alert.alert-warning p:before {
  content: "\f071";
}

@media (max-width: 991px) {
  #system-message-container {
    top: 61px;
  }
}

@include media-breakpoint-down(sm) {
  #system-message-container {
    margin-top: 3.7rem;
  }
}