.banner {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 40vh;
    padding-bottom: 1rem;
    padding-top: 1rem;


    & .home-banner-title {
        font-weight: 700;
    }
    &.home{
      background-position: top center;
    }
}