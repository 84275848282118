.project-card {
  background: $text-white;
  border-radius: $default-border-radius;
  box-shadow: 0 100px 80px rgba(28, 32, 37, 0.05),
    0 12.5216px 10.0172px rgba(28, 32, 37, 0.025);
  //margin-bottom: $project-card-spacing;
  display: flex;
  flex-direction: column;

  .project-footer{
  }
}

.project-detail {
  flex-grow: 1;
  $project-blurb-lines-n : 2; // Used for mininum number of lines to show

  p {
    //display: -webkit-box;
    //-webkit-line-clamp:  $project-blurb-lines-n;
    //-webkit-box-orient: vertical;
    //overflow: hidden;
    //
    //min-height: calc($font_size_18 * $project-blurb-lines-n * var(--bs-body-line-height));
    //
    //@media (max-width: 1600px) {
    //  min-height: calc($font_size_18_1600 * $project-blurb-lines-n * var(--bs-body-line-height));
    //}

  }

 .project-title{
   white-space: nowrap;
   overflow: hidden;
   text-overflow: ellipsis;
  }
}

// This is used on multiple pages. Home/Stories
.project-img {
  position: relative;
  min-height: 140px;
  border-radius: 0;

  img {
    height: 203px;
    object-fit: cover;
  }

  .project-status {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0.25rem 0.75rem;

    &.project-status-active {
      background: $brand-color;
      color: $text-white;
    }
    &.project-status-coming-soon{
      background: $secondary-color;
      color: $text-white;
    }
    &.project-status-completed{
      background: $light-grey;
      color: $text-black;
    }
  }
}

.feature-card-wrapper {
  background-color: white;
  &:not(:last-child) {
    margin-bottom: 1.5rem;
  }

  .theme-tile{
    background: $gray-200;
  }
  .box_content {
    padding: 3rem 1.5rem 3rem;
  }
}