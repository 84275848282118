
.img-overlay::after {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background: linear-gradient(180deg, rgba(28, 32, 37, 0) 53.12%, #1C2025 100%);
}
.bg-overlay-dark, .bg-overlay-light-2 {
    overflow:hidden;
}
.bg-overlay-dark .container, .bg-overlay-dark, .bg-overlay-light-2 .container, .bg-overlay-light-2 {
    position:relative;
}
.bg-overlay-dark:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgb(0 0 0 / 45%);
}

.bg-overlay-light-2:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgb(0 0 0 / 45%);
}

@mixin bg-overlay-mobile() {
  overflow: hidden;
  position: relative;
  .container {
    position: relative;
  }
  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgb(0 0 0 / 45%);
  }
}