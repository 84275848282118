
.component {
  &.window{
    padding: 0 !important;
    .mce-container *,
    .mce-container,
    .mce-container-body{
      font-size: 16px !important;
    }
    .mce-panel{
      overflow: hidden; // for iphone moving cursor outside the text box issue
    }

  }
  body{
    padding: 0 !important;
  }
  body {
    background-color: white !important;
  }
}

.window,
.window body{
}


.modal-content{
  border-radius: 10px;
  background: #fff !important;
}

.modal-body {

  & input,
  textarea,
  select {
    border: 1px solid #d7d7de;
    border-radius: 10px;
  }

  .red{
    color : red;
  }

  & input[type="checkbox"],
  & input[type="text"],
  & input[type="password"],
  & input[type="email"],
  select {
    height: 50px;
  }


  & input[type="checkbox"] {
    height: 1em;
  }
  @media screen and (max-width: 576px){
    .gutter{
     padding-top: 3rem;
    }
  }
  .custom-file-input {
    z-index: 2;
    width: 100%;
    height: 50px;
    opacity: 0;
    margin: 0;
    position: relative;
    overflow: hidden;
    border-radius: 10px;
    border: 1px solid #d7d7de;
    cursor: pointer;
  }
  & .custom-file-label {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1;
    height: 50px;
    padding: 15px 12px;
    overflow: hidden;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: $text-white;
    border: 1px solid #ced4da;
    border-radius: 10px;
    &.uploaded{
      padding: 7px 12px;
      @media screen and (max-width: 576px){
        display: flex;
      }
    }
    @media screen and (max-width: 576px) {
      padding: 4px 12px;
      .filename{
        max-width: 150px;
        display: inline-block;
      }
    }
  }
  & .custom-file-label::after {
    z-index: 3;
    height: 50px;
    color: $text-white;
    border-left: inherit;
    border-radius: 0 0.25rem 0.25rem 0;
    padding: 12px;
    line-height: 1.5;
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    background: #1c2025;
  }
  .custom-file-label.en::after{
    content: "Browse";
  }
  .custom-file-label.fr::after{
    content: "Parcourir";
  }
  .success {
    .green-success {
      color: #09793d;
    }
  }
}

// Magnificate over rides
.mfp-iframe {
  border-radius: 20px;
}


.mfp-wrap {
  z-index: 1100 !important;
}

.mfp-content {
  .white-popup-block {
    background: #f0f0f0;
    border-radius: 10px;
    max-width: 550px;
    margin: 40px auto;
    padding: 20px 15px;
    position: relative;
    text-align: left;
  }
}

.mpf-zindex-top{
  z-index: 1099 !important;
}

.mfp-abuse {
  .mfp-content {
    min-height: 555px;
  }
}