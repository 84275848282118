.popover-dark {
  background: rgba(28, 32, 37, 1);
  border-radius: 30px;

  .popover-header{
    background: transparent;
    border: none;
    color: #fff;
    font-size: 14px;
    font-weight: 400;
  }
  & .bs-popover-top>.popover-arrow:after {
    border-top-color: #000;
  }
  & .popover-body {
    color: #212529;
    padding: 4px 30px;
  }
}

.popover-dark.bs-popover-top>.popover-arrow:after,
.popover-dark.bs-popover-auto[data-popper-placement^="top"]>.popover-arrow:after {
  border-top-color: #1c2025 !important;
}
