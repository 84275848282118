// -----------------------------------------------------------------------------
// This file contains all styles related to the header of the site/application.
// -----------------------------------------------------------------------------
.custom-header {
  background-color: $bg-color;

  & a.navbar-brand {
    font-weight: 500;
    font-size: 20px;
    color: #1C2025;

    img {
      max-width: 100px;
      width: 100%;
      max-height: 50px;
      height: 100%;
    }
  }

  & a.nav-link {
    color: $text-color;
    font-weight: 600;
    font-size: $font-size-14;

    &:hover {
      color: $text-color;
      text-decoration: none;
    }

    text-transform: uppercase;
  }
}

.custom-header .navbar {
  padding: 0px;
  min-height: 60px;
}

.navbar-toggler:focus {
  box-shadow: none;
}

.height-44 {
  height: 44px;
}

.yellow-bar {
  background-color: $brand-color;
}

.input-group > .form-control:focus, .input-group > .form-select:focus {
  z-index: unset;
}

:focus-visible {
  outline: unset;
}


select#jform_com_fields_age_bracket:invalid {
  color: #6c757d;
}

select.form-control option {
  color: #212529;
}



@include media-breakpoint-down(sm) {
  #header-content {
    margin-top: 3rem;
  }

}

@media (min-width: 600px) and (max-width: 991px) {
  #header-content {
    margin-top: 3.6rem;
  }
}

/* for location map */
#pv_gmap {
  height: 250px;
  margin-top: 10px;
}